<template>
    <div class="qr-container">
        <h3>{{ $l("platon.scan", "QR-кодни сканерланг") }}</h3>
        <p>
            {{ $l("platon.scan_qr", "Э-имзо иловаси орқали ҚР-кодни сканерланг!") }}
        </p>

        <qrSignIn @close-modal="closeModal" :isEimzoTab="isEimzoTab" @getQrCode="setQrCode" />

        <div class="links">
            <a @click="directToApp" v-if="showDeepLink">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                >
                    <path
                        d="M192,136v72a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V80A16,16,0,0,1,48,64h72a8,8,0,0,1,0,16H48V208H176V136a8,8,0,0,1,16,0Zm32-96a8,8,0,0,0-8-8H152a8,8,0,0,0-5.66,13.66L172.69,72l-42.35,42.34a8,8,0,0,0,11.32,11.32L184,83.31l26.34,26.35A8,8,0,0,0,224,104Z"
                    ></path>
                </svg>
                <span>Open the E-imzo</span>
            </a>
            <div class="eimzo-downloads">
                <a
                    href="https://play.google.com/store/apps/details?id=uz.yt.idcard.eimzo"
                    target="_blank"
                    type="button"
                >
                    <i class="fab fa-google-play"></i>
                    <span>{{ $l("platon.google_play", "Google Play") }}</span>
                </a>

                <a
                    href="https://apps.apple.com/uz/app/e-imzo-id-%D0%BA%D0%B0%D1%80%D1%82%D0%B0/id1563416406"
                    target="_blank"
                    type="button"
                >
                    <i class="fab fa-app-store-ios"></i>
                    <span>{{ $l("platon.app_store", "App Store") }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import qrSignIn from "./qr-sign-in.vue"
import { isMobile } from "@Platon/core/helpers"
export default {
    name: "EIMZOQRTab",
    props: {
        isEimzoTab: Boolean
    },
    data() {
        return {
            code: "",
            showDeepLink: isMobile()
        }
    },
    components: {
        qrSignIn
    },
    methods: {
        closeModal() {
            this.$emit("close-modal")
        },

        setQrCode(qr) {
            this.code = qr
        },

        directToApp() {
            window.open("eimzo://sign?qc=" + this.code)
        }
    }
}
</script>

<style scoped lang="scss">
.qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px 15px;
    min-height: 200px;

    h3 {
        font-size: 24px;
        font-weight: 500;
    }

    p {
        opacity: 0.8;
        margin-bottom: 0;
        text-align: center;
    }
}

.qr-container .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        padding: 8px 14px;
        border-radius: 5px;
        background-color: var(--pl-login-eimzo-link-bg);
        color: var(--pl-top-bar);
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        transition: all 0.3s;

        &:hover {
            background-color: var(--pl-login-eimzo-link-hover);
        }

        &:active {
            background-color: var(--pl-login-eimzo-link-active);
        }

        i {
            font-size: 22px;
        }
    }
}

.eimzo-downloads {
    display: flex;
    gap: 3px;
    margin-top: 3px;
    width: 100%;
}

@media (max-width: 475px) {
    .qr-container h3 {
        font-size: 20px;
        margin-bottom: 0;
    }

    .qr-container p {
        font-size: 14px;
    }

    .qr-container .links {
        flex-direction: column;
        width: 100%;
    }

    .qr-container .links a {
        width: 100%;
    }
}
</style>
