import GostHash from "./pkcs"
import CRC32 from "./crc32"

export class EIMZOMobile {
	constructor(siteId) {
		this.siteId = siteId
	}

	makeQRCode(documentId, text) {
		const hasher = new GostHash()
		const textHash = hasher.gosthash(text)

		let code = this.siteId + documentId + textHash
		const crcer = new CRC32()

		const crc32 = crcer.calcHex(code)
		code += crc32

		return [textHash, code]
	}
}
