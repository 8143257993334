<template>
    <div>
        <div v-show="isLoading" class="el-container">
            <div class="loader"></div>
        </div>
        <div v-show="!isLoading && refresh" class="el-container" @click="signIn">
            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" fill="currentColor" viewBox="0 0 256 256">
                <path
                    d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"
                ></path>
            </svg>
        </div>
        <div v-show="!isLoading && !refresh" class="canvas-container">
            <canvas ref="qrCanvas" width="180" height="180"></canvas>
            <div v-if="arrayOf16">
                <div @click="copy" class="copy">
                    <span v-for="(chunk, index) in arrayOf16" :key="index">{{ chunk }}</span>
                </div>
                <div v-if="timeLeft > 0" class="timer">
                    <span>{{ formattedTimeLeft }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from "qrcode"
import { EIMZOMobile } from "./e-imzo-mobile.js"
import { isMobile } from "@Platon/core/helpers"
import { API_BASE_URL } from "@Platon/const"
import LoginMixin from "@Platon/components/login/LoginMixin"
import ToastMixin from "@Platon/mixins/ToastMixin"

export default {
    mixins: [LoginMixin, ToastMixin],
    data() {
        return {
            arrayOf16: null,
            qrCanvas: null,
            timer: null,
            timeLeft: 80,
            timerId: null,
            isLoading: true,
            statusTimeId: null,
            refresh: false
        }
    },
    props: {
        isEimzoTab: Boolean
    },
    computed: {
        formattedTimeLeft() {
            const minutes = Math.floor(this.timeLeft / 60)
                .toString()
                .padStart(2, "0")
            const seconds = (this.timeLeft % 60).toString().padStart(2, "0")
            return `${minutes}:${seconds}`
        }
    },
    methods: {
        clearStatusCheckerTimer() {
            clearTimeout(this.statusTimeId)
        },

        copy() {
            const text = this.arrayOf16.join("\n")
            navigator.clipboard.writeText(text)
        },

        startTimer() {
            this.timeLeft = 80
            this.clearAllTimer()
            if (this.timer) {
                clearInterval(this.timer)
            }
            this.timer = setInterval(() => {
                if (this.timeLeft > 0) {
                    this.timeLeft--
                } else {
                    this.clearAllTimer()
                    this.signIn()
                }
            }, 1000)
        },

        async checkStatus(documentId, challange) {
            try {
                const { data } = await this.$http.get(`auth/e-imzo/mobile/status`, {
                    params: { documentId },
                    baseURL: API_BASE_URL
                })

                const status = data.status

                if (status === 1) {
                    // continue
                    this.clearStatusCheckerTimer()
                    this.onCloseQrcodeModal()
                    await this.signInSuccess(documentId)
                } else if (status === 2) {
                    this.statusTimeId = setTimeout(async () => {
                        await this.checkStatus(documentId, challange)
                    }, 2000)
                    // if (pending.value) {
                    //     timerId.value = setTimeout(() => {
                    //         this.checkStatus(documentId, challange)
                    //     }, 1000)
                    // } else {
                    //     onCloseQrcodeModal()
                    //     showSnackbar({ message: t("Time_has_expired"), type: "error" })
                    // }
                } else {
                    this.onCloseQrcodeModal()
                }

                return data.value
            } catch (e) {
                this.errorToast(`Status error: ${e.message}`)
                throw new Error("makeAuth error")
            }
        },

        async signInSuccess(documentId) {
            this.isLoading = true
            try {
                const { data } = await this.$http.get(`auth/eimzo-v2/${documentId}`, {
                    baseURL: API_BASE_URL
                })
                this.$emit("close-modal")
                this.onLoginSuccess({ user: data.user, token: data.token })
            } catch (e) {
                this.isLoading = false
                this.refresh = true
                this.errorToast(`Eimzo-v2 error: ${e.message}`)
            }
        },

        async getChallenge() {
            const { data } = await this.$http.get("auth/e-imzo/mobile", {
                baseURL: API_BASE_URL
            })

            return data
        },

        clearStatusCheckerTimer() {
            clearTimeout(this.statusTimeId)
        },

        clearAllTimer() {
            this.clearStatusCheckerTimer()
            if (this.timer) {
                clearInterval(this.timer)
            }
        },

        async signIn() {
            try {
                this.refresh = false
                this.isLoading = true
                this.clearStatusCheckerTimer()
                const { challange, documentId, siteId } = await this.getChallenge()
                this.isLoading = false
                if (!challange) {
                    throw new Error("Challenge retrieval failed")
                }

                this.startTimer()

                const eim = new EIMZOMobile(siteId)
                const res = eim.makeQRCode(documentId, challange)

                const hashedQrCode = res[0]
                const qr = res[1]
                this.$emit("getQrCode", qr)
                this.arrayOf16 = hashedQrCode.match(/.{1,16}/g)

                this.generateQRCode(qr)

                this.statusTimeId = setTimeout(async () => {
                    await this.checkStatus(documentId, challange)
                }, 2000)
            } catch (e) {
                this.isLoading = false
                this.refresh = true
                this.errorToast(`Sign in error: ${e.message}`)
            }
        },
        generateQRCode(qr) {
            QRCode.toCanvas(this.$refs.qrCanvas, qr, function (error) {
                if (error) console.error("Error generating QR Code:", error)
            })
        },

        onCloseQrcodeModal() {
            clearInterval(this.timerId)
        }
    },
    mounted() {
        if (!this.isEimzoTab) {
            this.signIn()
        }
    },
    beforeDestroy() {
        this.clearAllTimer()
        this.clearStatusCheckerTimer()
        this.onCloseQrcodeModal()
        if (this.timer) {
            clearInterval(this.timer)
        }
    },
    watch: {
        isEimzoTab(newValue) {
            if (!newValue) {
                this.signIn()
            } else {
                this.clearStatusCheckerTimer()
                if (this.timer) {
                    clearInterval(this.timer)
                }
            }
        }
    }
}
</script>
<style scoped lang="scss">
.canvas-container {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 8px;

    @media ((max-width: 450px) and (min-width: 320px)) {
        gap: 0px;
    }
}

.canvas-container div span {
    display: block;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1px;
    font-family: monospace;
}

.canvas-container .timer {
    text-align: center;
    margin: 5px 0;
}

.canvas-container div.copy {
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s;
}

.canvas-container div.copy:hover {
    background-color: var(--pl-login-eimzo-tab-copy-bg-hover);
}

.loader-container {
    height: 188px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 40px;
    margin: 10px 0;
    padding: 5px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #335d99;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    to {
        transform: rotate(1turn);
    }
}

.el-container {
    height: 188px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-container svg {
    cursor: pointer;
}
</style>
